/* @import url('https://fonts.googleapis.com/css?family=Merriweather'); */

.changeColor {
    fill: #4f86ed;
}

/* #title {
    font-size: 50%;
    font-family: 'Merriweather', serif;
} */

.cls-1 {
    opacity: 0.3;
}

.cls-7 {
    opacity: 0.8;
}

.cls-2 {
    fill: #fff;
}

.cls-10,
.cls-11,
.cls-12,
.cls-14,
.cls-16,
.cls-3 {
    fill: none;
}

.cls-3 {
    stroke: #5c7690;
}

.cls-10,
.cls-11,
.cls-12,
.cls-3 {
    stroke-miterlimit: 10;
}

.cls-14,
.cls-15,
.cls-16,
.cls-3 {
    stroke-width: 0.5px;
}

.cls-4 {
    fill: #ffe1d9;
}

.cls-5 {
    fill: #ffcfbf;
}

.cls-6 {
    fill: #fecbb6;
}

.cls-9 {
    fill: #fecb02;
}

.cls-10,
.cls-12 {
    stroke: #d26f51;
}

.cls-10,
.cls-11 {
    stroke-width: 0.38px;
}

.cls-11 {
    stroke: #000;
}

.cls-12 {
    stroke-width: 0.19px;
}

.cls-13 {
    opacity: 0.45;
}

.cls-14,
.cls-15,
.cls-16 {
    stroke: #b0bec5;
    stroke-linejoin: round;
}

.cls-15 {
    fill: #edf0f2;
}

.cls-16 {
    stroke-linecap: round;
}

.cls-17 {
    font-family: 'PT Sans', sans-serif;
    font-size: 49.87px;
    font-weight: 700;
}

.cls-18 {
    fill: #fffdbb;
    opacity: 0.5;
}


/*---------------------------
        SVG Animate
---------------------------*/
.earMove {
    transition: all ease-in-out 2s;
    transform-origin: 50% 50%;
    -webkit-animation: earmove 1.5s linear infinite alternate;
            animation: earmove 1.5s linear infinite alternate;
}

.faceMove {
    transition: all ease-in-out 2s;
    transform-origin: 50% 50%;
    -webkit-animation: move 1.5s linear infinite alternate;
            animation: move 1.5s linear infinite alternate;
}

.neckMove {
    transition: all ease-in-out 2s;
    transform-origin: 50% 50%;
    -webkit-animation: neck 1.5s linear infinite alternate;
            animation: neck 1.5s linear infinite alternate;
}


@-webkit-keyframes earmove {
    0% {
        transform: translateX(-0.3px) translateY(0.6px);
    }

    30% {
        transform: translateX(-0.3px) translateY(0.6px);
    }

    60% {
        transform: translateX(-0.7px) translateY(0px);
    }

    70% {
        transform: translateX(-0.7px) translateY(-0.3px);
    }

    100% {
        transform: translateX(-0.7px) translateY(-0.3px);
    }
}


@keyframes earmove {
    0% {
        transform: translateX(-0.3px) translateY(0.6px);
    }

    30% {
        transform: translateX(-0.3px) translateY(0.6px);
    }

    60% {
        transform: translateX(-0.7px) translateY(0px);
    }

    70% {
        transform: translateX(-0.7px) translateY(-0.3px);
    }

    100% {
        transform: translateX(-0.7px) translateY(-0.3px);
    }
}

@-webkit-keyframes move {
    0% {
        transform: translateX(-0.3px) translateY(0.6px);
    }

    30% {
        transform: translateX(-0.3px) translateY(0.6px);
    }

    60% {
        transform: translateX(2px) translateY(0px);
    }

    70% {
        transform: translateX(2px) translateY(-0.3px);
    }

    100% {
        transform: translateX(2px) translateY(-0.3px);
    }
}

@keyframes move {
    0% {
        transform: translateX(-0.3px) translateY(0.6px);
    }

    30% {
        transform: translateX(-0.3px) translateY(0.6px);
    }

    60% {
        transform: translateX(2px) translateY(0px);
    }

    70% {
        transform: translateX(2px) translateY(-0.3px);
    }

    100% {
        transform: translateX(2px) translateY(-0.3px);
    }
}

@-webkit-keyframes neck {
    0% {
        transform: translateY(0.7px);
    }

    50% {
        transform: translateY(0.7px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes neck {
    0% {
        transform: translateY(0.7px);
    }

    50% {
        transform: translateY(0.7px);
    }

    100% {
        transform: translateY(0px);
    }
}
